import { axiosClient } from '@/services/axios-client';

const getAppDownloadInfo = (extraOptions) => {
    return axiosClient.get('/desktop/api.windows/v2/download/', extraOptions);
};
const getFlagshipAppDownloadInfo = () => axiosClient.get('/nextgen/download');

const DownloadClient = {
    getAppDownloadInfo,
    getFlagshipAppDownloadInfo,
};

export { DownloadClient };