<template>
  <div class="download-success-view">
    <PageLayout
      v-if="!isDownloading"
      :title="$t('loginAccountSuccess.title')"
    >
      <a
        class="simple-link"
        @click.prevent="onClickLink"
      >
        {{ $t('loginAccountSuccess.download.redirect') }}
      </a>
    </PageLayout>
    <PageLayout
      v-else
      :title="$t('loginAccountSuccess.download.title')"
    >
      <p class="download-success-view__content-text">
        {{ $t('loginAccountSuccess.open-file') }}
      </p>
      <i18n path="loginAccountSuccess.download-link">
        <a
          place="download_link"
          class="simple-link"
          @click.prevent="onClickLink"
          v-html="$t('loginAccountSuccess.here')"
        />
      </i18n>
      <p
        class="download-success-view__content-text"
        v-html="$t('loginAccountSuccess.help-link')"
      />
      <i18n
        v-if="isOldUser"
        path="loginAccountSuccess.looking-for-v2"
      >
        <a
          place="download_link"
          class="simple-link"
          @click.prevent="onClickV2Link"
          v-html="$t('loginAccountSuccess.here')"
        />
      </i18n>
    </PageLayout>
  </div>
</template>

<script>
const ONE_YEAR_IN_SECONDS = 12 * 30 * 24 * 60 * 60;

import { mParticleMetric } from '@/metrics';
import { CookieService } from '@/services/cookie';
import { LOGIN_ACTIONS_CONSTANTS } from '@/domain/login';
import { ROUTER_PATHS } from '@/router/paths';
import { metricsService } from  '@/metrics/metrics.js';
import { DownloadClient } from '@/services/download';
import PageLayout from '@/components/PageLayout/PageLayout.vue';
import * as Sentry from '@sentry/vue';

const USER_ATTRIBUTE_AB_TEST_SPLIT = 'Web_AB_Test_Split';

export default {
    components: {
        PageLayout,
    },
    data() {
        return {
            isDownloading: false,
            isOldUser: false,
            userId: null,
            isNewUser: false,
            downloadInfo: null,
            forceV2: false,
        };
    },
    async mounted() {
        this.userId = this.$route.query.userId;
        this.isNewUser = this.$route.query.isNew === 'true';
        this.forceV2 = this.$route.query.forcev2 === 'true';

        const cookieUserId = CookieService.getCookie('userId');
        if (this.userId !== cookieUserId) {
            this.$router.push({
                path: ROUTER_PATHS.HOME,
                query: {
                    action: LOGIN_ACTIONS_CONSTANTS.DOWNLOAD,
                    forceV2: this.forceV2,
                },
            });
            return;
        }

        this.$store.dispatch('user_info_store/axn_setShowProfile', true);
        CookieService.setCookie('userId', this.userId);

        metricsService.initMetrics();
        mParticleMetric.track(mParticleMetric.EVENTS.DOWNLOAD_VIEW);

        const START_DOWNLOAD_TIMEOUT = 2000;

        try {
            const customHeader = {
                'Content-Type': 'application/json',
                'X-Creation-Date': this.getUserCreationDate(),
            };

            this.isOldUser = this.shouldSeeV2Download();

            const { data } = await DownloadClient.getAppDownloadInfo({
                headers: customHeader,
            });
            this.downloadInfo = data;

            // Set downloadId if is not present and returned by server
            const downloadId = CookieService.getCookie('download_id');
            if (!downloadId && data.downloadId) {
                const expiresInOneYear = parseInt(Date.now() / 1000) + ONE_YEAR_IN_SECONDS;
                CookieService.setCookie('download_id', data.downloadId, expiresInOneYear );
            }

            if (data.Web_AB_Test_Split) {
                mParticleMetric.setUserAttribute(USER_ATTRIBUTE_AB_TEST_SPLIT, data.Web_AB_Test_Split);
            }

            setTimeout(() => this.onDownloadStart(), START_DOWNLOAD_TIMEOUT);
        } catch (error) {
            Sentry.captureMessage('Request to download endpoint failed');
            Sentry.captureException(error);
            mParticleMetric.track(mParticleMetric.EVENTS.ACCOUNTS_WEB_UNEXPECTED_ERROR_OCCURRED, {
                login_error_reason: 'request_to_download_endpoint_failed',
            });
            this.$router.push(ROUTER_PATHS.ACCOUNT_ACTIVATED_ERROR);
            return;
        }
    },
    methods: {
        onDownloadStart() {
            this.isDownloading = true;
            this.sendDownloadMetrics();
            window.location.href = this.getDownloadUrlAndVersion().url;
        },
        onClickLink() {
            const { url, version, isV2Forced } = this.getDownloadUrlAndVersion();
            mParticleMetric.track(mParticleMetric.EVENTS.DOWNLOAD_TRIGGERED, {
                source: 'click',
                app_version: version,
                force_v2: isV2Forced,
            });
            window.location.href = url;
        },
        onClickV2Link() {
            const { url, version, isV2Forced } = this.getDownloadUrlAndVersionV2();
            mParticleMetric.track(mParticleMetric.EVENTS.DOWNLOAD_TRIGGERED, {
                source: 'click',
                app_version: version,
                force_v2: isV2Forced,
            });
            window.location.href = url;
        },
        sendDownloadMetrics() {
            const { url, version, isV2Forced } = this.getDownloadUrlAndVersion();

            if (mParticleMetric) {
                mParticleMetric.track(mParticleMetric.EVENTS.DOWNLOAD_TRIGGERED, {
                    source: 'auto',
                    app_version: version,
                    force_v2: isV2Forced,
                });
            }
            if (window.dataLayer) {
                window.dataLayer.push({event: 'downloadTriggered'});
                window.dataLayer.push({
                    'event': 'Download Triggered',
                    'product': 'V2',
                    'app_version': version,
                    'file_name': url,
                });
            }
        },
        deleteOptimizeExperiment() {
            CookieService.deleteCookie('ab_test');
        },
        showedPromotion() {
            mParticleMetric.track(mParticleMetric.EVENTS.UNLOCK_PROMOTION_SHOWED, {
                banner_parent_page: 'download',
            });
        },
        onShareClick(socialNetwork) {
            mParticleMetric.track(mParticleMetric.EVENTS.UNLOCK_PROMOTION_CLICKED, {
                social_network: socialNetwork,
                banner_parent_page: 'download',
            });
        },
        getDownloadUrlAndVersion() {
            if (this.forceV2 && this.downloadInfo.urlV2) {
                return { url: this.downloadInfo.urlV2, version: this.downloadInfo.app_version_v2, isV2Forced: true };
            }

            return { url: this.downloadInfo.url, version: this.downloadInfo.app_version, isV2Forced: false };
        },
        getDownloadUrlAndVersionV2() {
            if (this.downloadInfo.urlV2) {
                return { url: this.downloadInfo.urlV2, version: this.downloadInfo.app_version_v2, isV2Forced: true };
            }
            return { url: this.downloadInfo.url, version: this.downloadInfo.app_version, isV2Forced: false };
        },
        getUserCreationDate() {
            const userInfo = sessionStorage.getItem('userInfo');

            if (!userInfo) {
                return '';
            }

            const sessionUserInfo = JSON.parse(userInfo);

            return sessionUserInfo.createdAt;
        },
        shouldSeeV2Download() {
            const createdAt = this.getUserCreationDate();

            if (createdAt === '') {
                return false;
            }

            const userAgeInDays = Math.floor((Date.now() - Date.parse(createdAt)) / 1000 / 24 / 60 / 60);

            return Date.parse('2024-04-23') > Date.parse(createdAt);
        },
    },
};
</script>

<style src="./DownloadSuccessView.scss" lang="scss"></style>
